@font-face {
    font-family: "poppins";
    src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
}

.App {
    overflow: hidden;
    background-image: url("dynamicbg.gif");
    background-size: 100%;
}

.app {
    text-align: center;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    scroll-behavior: smooth;
    background-color: rgba(13, 0, 70, 1);
    padding-right: 30px;
    margin-bottom: 20px;
    z-index: 1;
    z-index: 100;
}

.app-navbar {
    overflow: hidden;
    position: fixed;
    width: 100%;
    background-color: rgba(13, 0, 70, 1);
    padding-right: 30px;
    margin-bottom: 20px;
    z-index: 5;
}

.app-navbar a {
    color: white;
    float: right;
    padding: 16px 20px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    font-family: monospace;
}


.app-navbar a:hover {
    background-color: rgba(2, 0, 14, 0.852);
    color: rgb(255, 255, 255);
    font-weight: bolder;
    box-shadow: 0px 3px 0px rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

.app-header {
    position: static;
    height: 500px;
    display: inline-block;
    width: 100%;
}

.welcome {
    width: max-content;
    padding: 20px;
    position: absolute;
    top: 20%;
    left: 10%;
}

.welcome-2 {
    width: max-content;
    padding: 20px;
    position: absolute;
    top: 30%;
    
    transition-duration: 1s;
    left: 10%;
}

.welcome-2:hover {
    transition: 1s;
    font-display: bold;
    box-sizing: content-box;
    box-shadow: 0px 3px 0px rgba(255, 255, 255, 0.4);
    width: fit-content;
    cursor: pointer;
    color: antiquewhite;
}

#video {
    position: fixed;
    z-index: -1;
    width: 100%;
}

.app-container {
    width: 60%;
    color: white;
}

.pfp {
    width: 300px;
    position: absolute;
    top: 15%;
    right: 10%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 50%;
    overflow: hidden;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
    box-shadow: 5px 10px 15px 5px rgba(245, 245, 245, 0.845);
}

.about-content{    
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
    margin: 10px;
    width: 90%;
    display: flex;    
    color: white;
}

.about-text {
    text-align: justify;
    width: 80%;
    padding: 10px;
    color: white;
    font-size: 18px;
}

.clouds {
    width: 120px;
    position: absolute;
    left: 5px;
    overflow: hidden;
    margin: 5px;
    transform: translatey(0px);
    animation: float 10s ease-in-out infinite;
}

.astro1 {
    width: 120px;
    position: absolute;
    left: 30%;
    overflow: hidden;
    transform: translatey(0px);
    animation: float 5s ease-in-out infinite;
}

.clouds-2 {
    width: 120px;
    position: absolute;
    right: 3px;
    margin: 5px;
    overflow: hidden;
    transform: translatey(0px);
    animation: float 10s ease-in-out infinite;
}

.sphere {
    width: 160px;
    position: absolute;
    left: 5%;
    padding: 5px;
    overflow: hidden;
    margin: 5px;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
}

.sphere-2 {
    width: 180px;
    position: absolute;
    right: 3%;
    padding: 5px;
    margin: 5px;
    overflow: hidden;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
}

@keyframes float {
    0% {
        box-shadow: 0 5px 15px 0px rgba(255, 255, 255, 0);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 25px 15px 0px rgba(255, 255, 255, 0);
        transform: translatey(-30px);
    }

    100% {
        box-shadow: 0 5px 15px 0px rgba(255, 255, 255, 0);
        transform: translatey(0px);
    }
}

.section-heading {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4);
    font-size: 3rem;
    margin-bottom: 20px;
    font-family: monospace;
    color: white;
    
    transition-duration: 1s;
}

.section-heading:hover {
    transition: 1s;
    font-size: 3.2rem;
    font-display: bold;
    box-shadow: 0px 5px 0px rgba(255, 255, 255, 0.616);
    width: fit-content;
    cursor: pointer;
    color: rgb(232, 145, 252);
}

.about {
    padding: 10px;
    margin-bottom: 20px;
    background: linear-gradient(109.6deg, rgb(9, 9, 121) 11.2%, rgb(144, 6, 161) 53.7%, rgb(0, 212, 255) 100.2%);
    background-size: 600% 100%;
    animation: gradient 8s linear infinite;
    animation-direction: alternate;
    width: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0%
    }

    100% {
        background-position: 100%
    }
}

.experience {
    padding: 10px;
    background: linear-gradient(109.6deg, rgb(9, 9, 121) 11.2%, rgb(144, 6, 161) 53.7%, rgb(0, 212, 255) 100.2%);
    background-size: 600% 100%;
    animation: gradient 8s linear infinite;
    animation-direction: alternate;
    width: 100%;
}

.astro-4 {
    width: 160px;
    position: absolute;
    right: 20%;
    padding: 5px;
    overflow: hidden;
    margin: 5px;
    transform: translatey(0px);
    animation: float 10s ease-in-out infinite;
}

.astro-3 {
    width: 160px;
    position: absolute;
    left: 20%;
    padding: 5px;
    margin: 5px;
    transform: translatey(0px);
    animation: float 10s ease-in-out infinite;
}

.astro-2 {
    width: 180px;
    position: absolute;
    right: 20%;
    transform: translatey(0px);
    animation: float 10s ease-in-out infinite;
}

.spring {
    width: 170px;
    position: absolute;
    left: 5%;
    margin: 10px;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
}

.diamond {
    width: 160px;
    position: absolute;
    right: 25px;
    margin: 10px;
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
}


@media screen and (max-width: 1000px) {
    .app-navbar a {
        color: white;
        float: center;
        padding: 10px 14px;
        text-decoration: none;
        text-align: center;
        font-size: 12px;
        font-family: monospace;
    }

    .app-navbar a:hover {
        color: rgb(232, 145, 252);
        transition: 0.5s;
        
    }
    .app-navbar {
        width: 100%;
    }

    .pfp {
        width: 180px;
        top: 20%;
        left: 35%;
        float: center;
    }

    #video {
        width: max-content;
        height: 100%;
    }

    .section-heading {
        font-size: 2rem;
    }

    .section-heading:hover {
        font-size: 2.3rem;
    }

    .welcome {
        padding: 20px;
        position: absolute;
        top: 40%;
        left: 10%;
        font-size: 1.5rem;
        align-self: center;
    }

    .welcome-2 {
        padding: 20px;
        position: absolute;
        top: 48%;
        align-self: center;
        font-size: 1.2rem;
    }

    .clouds,
    .clouds-2 {
        width: 100px;
        right: 0px;
    }

    .astro1 {
        width: 100px;
        left: 20px;
    }

    .sphere {
        width: 80px;
        left: 5px;
    }

    .sphere-2 {
        width: 80px;
        margin-bottom: 20px;
        right: 5px;
    }

    .astro-4 {
        width: 100px;
        right: 2px;
    }

    .astro-3 {
        width: 100px;
        left: 2px;
    }

    .astro-2 {
        width: 120px;
        right: 2px;
    }

    .spring {
        width: 80px;
        left: 2px;
    }

    .diamond {
        width: 80px;
        right: 2px;
    }
}

.social-media {
    display: flex;
    justify-content: center;
    width: 70%;
    flex-direction: row;
    flex-wrap: wrap;
  
}
  
  .social-icon {
    display: inline-block;
    margin: 5px 10px;
  }
  
  .social-icon img {
    width: 50px;
    height: 50px;
    margin: 2px;
    padding: 1px;
    transition-duration: 1s;
    border: 2px solid white;
    border-radius: 50%;
    background-color: white;
  }

  .social-icon img:hover{
    width: 50px;
    scale: 1.3;
    height: 50px;
    transition-duration: 1s;
  }